<template>
	<Fragment>
		<header>
			<!-- Logo area -->
			<div class="logo">
				<a href="/login" class="logo-link">
					<i class="img-logo"></i>
					고객 포털 서비스
				</a>
			</div>
			<!-- util area : 로그인 후, util 영역 -->
		</header>
		<div class="body-wrap sign-up">
			<div class="header">
				<!-- @230720 .text-center 추가 -->
				<h1 class="text-center">
					다우오피스 이용을 위해 <br />
					회원가입 후 서비스를 신청해주세요!
				</h1>
			</div>
			<div class="card" v-if="!canSendConfirmEmail">
				<!-- contents -->
				<div class="contents-wrap">
					<!-- 로그인 정보 -->
					<LoginInfoItem
						:loginInfoProps="getLoginItemInfo"
						:loginInvalidClassProps="loginInvalidClass"
						:isSnsSign="isSnsSign"
						ref="loginComponent"
						@setLoginInfo="setLoginInfo"
						:key="`${loginComponentKey.prefix}${loginComponentKey.idx}`"
						:isCustomer="isCustomer"
					></LoginInfoItem>
					<h3 class="label body-2-m asterisk">
						회원 유형
						<i class="ic-16 ic-info" v-show="domsCodeInfo.domsInvite">
							<div class="tooltip-layer left">
								- 초대를 통해 기업 회원가입을 진행합니다.<br /><br />
								- 취소를 선택하시면, 로그인 페이지로 이동합니다.<br /><br />
								- 취소/새로고침 시, 초대 회원가입이 불가능합니다.<br /><br />
								- 초대 코드가 삭제되면 다시 이메일 링크를 통해 <br />
								초대 회원가입 진행이 가능합니다.
							</div>
						</i>
						<i class="ic-16 ic-info" v-show="daouofficeCode || isNormalInvite">
							<div class="tooltip-layer left">
								- 초대를 통해 개인 회원가입을 진행합니다.<br /><br />
								- 취소를 선택하시면, 로그인 페이지로 이동합니다.<br /><br />
								- 새로고침 시, 초대 회원가입이 불가능합니다.<br /><br />
								- 초대 코드가 삭제되면 다시 이메일 링크를 통해<br />
								초대 회원가입 진행이 가능합니다.
							</div>
						</i>
					</h3>

					<!-- tab -->
					<div class="tab-wrap" id="testMainTab">
						<ul class="tab-items block-type">
							<li
								:class="[signupCategory === 'customer' ? 'tab on' : 'tab']"
								@click="
									() => {
										if (daouofficeCode || isNormalInvite) return;
										this.signupCategory = 'customer';
										this.isSnsSign = false;
										this.clearManagerInfo();
									}
								"
							>
								<a id="testTab01">
									<i class="ic-24 ic-company"></i>
									<span class="tab-text">기업회원 {{ daouofficeCode || isNormalInvite ? '(선택불가)' : '' }}</span>
								</a>
							</li>
							<li
								:class="[signupCategory === 'individual' ? 'tab on' : 'tab']"
								@click="
									() => {
										if (domsCodeInfo.domsInvite) return;
										this.signupCategory = 'individual';
										this.isSnsSign = false;
										this.clearManagerInfo();
									}
								"
							>
								<a id="testTab02">
									<i class="ic-24 ic-user-thin"></i>
									<span class="tab-text">개인회원 {{ domsCodeInfo.domsInvite ? '(선택불가)' : '' }}</span>
								</a>
							</li>
						</ul>
						<div class="tab-contents" id="testCompanyForm">
							<div v-if="signupCategory === 'customer'">
								<CustomerSignup
									@updateConfirmSigninParam="updateConfirmSigninParam"
									:linkCode="domsCode"
									:isDomsInvite="domsCodeInfo.domsInvite"
									:businessLicenseNumProps="domsCodeInfo.businessLicenseNum"
									:corporateNameProps="domsCodeInfo.corporateName"
									:ceoNameProps="domsCodeInfo.ceoName"
									:key="customerSignupKey"
									@disableCloseCorifirm="disableCloseCorifirm"
									:updateLoginInfo="{ loginInfo, loginInvalidClass }"
									@updateManagerInfo="updateManagerInfo"
								></CustomerSignup>
							</div>
							<!-- @include 02_회원가입_기업 -->
						</div>
						<div class="tab-contents" id="testPersonForm">
							<div v-if="signupCategory === 'individual'">
								<IndividualSignup
									:snsEmail="snsEmailInfo"
									:snsProfileUrl="snsProfileUrlInfo"
									:snsName="snsNameInfo"
									:snsType="snsTypeInfo"
									:snsId="snsIdInfo"
									:snsCi="snsCiInfo"
									@disableCloseCorifirm="disableCloseCorifirm"
									@clearSns="clearSnsInfo"
									@updateConfirmSigninParam="updateConfirmSigninParam"
									@clearLinkCode="clearAllCode"
									@setSnsSign="setSnsSign"
									:updateLoginInfo="{ loginInfo, loginInvalidClass }"
									@updateManagerInfo="updateManagerInfo"
								></IndividualSignup>
							</div>
							<!-- @include 02_회원가입_개인 -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import IndividualSignup from '@/components/login/IndividualSignup';
import CustomerSignup from '@/components/login/CustomerSignup';
import mixinsAlert from '@/components/common/mixinsAlert';
import LoginInfoItem from '@/components/login/LoginInfoItem';
export default {
	name: 'Signup',
	components: { LoginInfoItem, IndividualSignup, CustomerSignup },
	props: {
		snsEmail: { type: String, default: '' },
		snsProfileUrl: { type: String, default: '' },
		snsName: { type: String, default: '다우' },
		snsType: { type: String, default: '' },
		snsId: { type: String, default: '' },
		snsCi: { type: String, default: '' },
	},
	mixins: [mixinsAlert],
	data() {
		return {
			signupCategory: 'customer',
			snsEmailInfo: '',
			snsProfileUrlInfo: '',
			snsNameInfo: '',
			snsTypeInfo: '',
			snsIdInfo: '',
			snsCiInfo: '',
			canSendConfirmEmail: false,
			receiveEmail: '',
			loginId: '',
			signupConfirmComponentKey: 0,
			domsCode: '',
			domsCodeInfo: {
				domsInvite: false,
			},
			customerSignupKey: 0,
			inviteCode: '',
			daouofficeCode: this.$store.getters['getDaouofficeCode'],
			diabledCloseConfirmAlert: false,
			loginInfo: {
				loginId: '',
				password: '',
				passwordConfirm: '',
			},
			loginInvalidClass: {
				loginId: null,
				password: null,
				passwordConfirm: null,
				loginIdReason: null,
				passwordReason: null,
				passwordConfirmReason: null,
			},
			isSnsSign: false,
			isCustomer: false,
			managerInfo: {
				phoneNumber: '',
				name: '',
				birthDay: '',
				email: '',
				rank: '',
				tel: '',
				ci: '',
			},
			managerInvalidClass: {
				ci: null,
				ciReason: null,
				email: null,
				emailReason: null,
			},
			loginComponentKey: {
				prefix: 'login',
				idx: 0,
			},
		};
	},
	created() {
		this.validateDuplicateLinkCode();
		this.isDomsInviteSignup();
		window.addEventListener('beforeunload', this.unLoadEvent);
		window.addEventListener('pageshow', (event) => {
			if (event.persisted) {
				this.clearLinkCodeInStore();
			}
		});
		this.setSnsInfoByProps();
	},
	computed: {
		isNormalInvite() {
			return this.domsCode && !this.domsCodeInfo.domsInvite;
		},
		getLoginItemInfo() {
			return {
				loginId: this.loginInfo.loginId,
				password: this.loginInfo.password,
				passwordConfirm: this.loginInfo.passwordConfirm,
				email: this.managerInfo.email,
				birthday: this.managerInfo.birthDay,
				phoneNumber: this.managerInfo.phoneNumber,
			};
		},
		getLoginKey() {
			return this.loginComponentKey.prefix + this.termsComponentKey.idx;
		},
	},
	methods: {
		setSignupCategory(domsCodeInfo) {
			this.signupCategory =
				this.snsCi || this.daouofficeCode || (this.domsCode && !domsCodeInfo.domsInvite) ? 'individual' : 'customer';
		},
		clearSnsInfo() {
			this.snsEmailInfo = '';
			this.snsProfileUrlInfo = '';
			this.snsNameInfo = '';
			this.snsType = '';
			this.snsTypeInfo = '';
			this.snsCiInfo = '';
		},
		clearManagerInfo() {
			this.managerInfo = {
				phoneNumber: '',
				name: '',
				birthDay: '',
				email: '',
				rank: '',
				tel: '',
				ci: '',
			};
		},
		updateConfirmSigninParam(state) {
			this.loginId = state.loginId;
			this.password = state.password;

			// 회원가입 성공 시 자동 로그인
			this.login();
		},
		updateManagerInfo(data) {
			this.managerInfo = { ...data };
		},
		login() {
			this.$store
				.dispatch('login', { loginId: this.loginId, password: this.password })
				.then(() => {
					this.$router.replace('/apply/step1');
				})
				.catch((response) => {
					if (response.data.errorCode === 'ERROR211') {
						this.observeErrCode(response.data.errorCode, { loginId: this.loginId, password: this.password });
					} else {
						this.observeErrCode(response.data.errorCode, response.data.errorMessage, this.setLoginErrorMessage);
					}
				});
		},
		unLoadEvent(event) {
			this.clearLinkCodeInStore();
		},
		async isDomsInviteSignup() {
			this.domsCode = this.$store.getters['getLinkCodes'].inviteCode;
			if (this.domsCode) {
				this.domsCodeInfo = await this.$store.dispatch('getIsSignupCustomer', this.domsCode);
				this.customerSignupKey += 1;
			}
			this.setSignupCategory(this.domsCodeInfo);
		},
		validateDuplicateLinkCode() {
			const codes = this.$store.getters['getLinkCodes'];
			let count = 0;
			for (const code in codes) {
				// 초대 코드가 실제로 담겨있어야 count를 늘려준다.
				count = codes[code] ? count + 1 : count;
			}
			if (count > 1) {
				this.alert({
					contents: '초대 코드를 중복하여 가지고 있을 수 없습니다.',
					buttons: [
						{
							text: '로그인으로',
							callback: () => {
								this.clearLinkCodeInStore();
								this.$router.replace('/login');
							},
						},
						{
							text: '일반회원가입',
							callback: () => {
								this.clearLinkCodeInStore();
								this.clearAllCode();
							},
						},
					],
				});
			}
		},
		clearLinkCodeInStore() {
			this.$store.commit('CLEAR_LINK_CODES');
		},
		clearAllCode() {
			this.domsCode = '';
			this.domsCodeInfo.domsInvite = false;
			this.daouofficeCode = '';
			this.inviteCode = '';
			this.signupCategory = 'customer';
		},
		disableCloseCorifirm() {
			this.diabledCloseConfirmAlert = true;
		},
		setSnsInfoByProps() {
			this.snsEmailInfo = this.snsEmail;
			this.snsProfileUrlInfo = this.snsProfileUrl;
			this.snsNameInfo = this.snsName;
			this.snsTypeInfo = this.snsType;
			this.snsIdInfo = this.snsId;
			this.snsCiInfo = this.snsCi;
		},
		setLoginInfo(loginData, loginInvalidData) {
			this.loginInfo = { ...loginData };
			this.loginInvalidClass = { ...loginInvalidData };
		},
		setSnsSign(isSnsSign) {
			this.isSnsSign = isSnsSign;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (this.diabledCloseConfirmAlert) {
			// 회원가입 완료 혹은 회원가입 실패 Alert 시에는, Confirm 메시지가 뜨지 않도록 만들기
			next();
			return;
		}
		const answer = window.confirm('회원가입을 진행중입니다. 이 페이지를 나가시겠습니까?');
		this.clearLinkCodeInStore();
		if (answer) {
			next();
		} else {
			next(false); // false를 추가한 경우 페이지 이동을 하지 않음
		}
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.unLoadEvent);
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/signup.css';
</style>
