<template>
	<Fragment>
		<!-- // style에 들어있는 스타일은 본 문서에서만 사용합니다. -->
		<!-- header -->
		<div class="header">
			<h1 class="heading-3-b">회원가입</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>
		<!-- contents-wrap -->
		<div class="contents-wrap">
			<div v-if="!isSnsSign">
				<p class="description">회원가입 방식을 선택해 주세요.</p>
				<div class="form">
					<div class="">
						<label class="label">직접 입력을 통한 회원가입</label>
						<IdentityVerificationButton code-type="signup" v-on:complete="handleComplete" :className="'solid full'" />
					</div>
					<div class="">
						<label class="label">간편 회원가입</label>
						<button class="solid naver full" @click="sns('naver')">네이버로 시작하기</button>
						<button class="solid kakao full disabled" @click="sns('kakao')" disabled>카카오로 시작하기</button>
					</div>
					<div class="group-text text-center mb-24">
						<p>이미 회원이신가요?</p>
						<button class="text" @click="handleClose">로그인으로</button>
					</div>
				</div>
			</div>
			<div v-else class="text-center">
				<p>
					<strong>{{ this.getSnsType }}</strong>
					의
					<strong>{{ this.name }}</strong>
					님 <br />
				</p>
				<p class="description">서비스 이용을 위해 본인 확인이 필요합니다.</p>
				<div class="form">
					<div class="">
						<IdentityVerificationButton v-on:complete="handleComplete" :className="'solid full'" />
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import mixinsAlert from '@/components/common/mixinsAlert';

export default {
	name: 'SignupLayer',
	components: { IdentityVerificationButton },
	mixins: [mixinsAlert],
	props: {
		name: String,
		snsType: String,
		handleCompleteFromParent: Function,
	},
	data() {
		return {
			isSnsSign: Boolean,
			backUrl: process.env.VUE_APP_API_URL,
			frontUrl: process.env.VUE_APP_FRONT_SERVER,
		};
	},
	created() {
		// if (this.snsType) {
		// 	this.isSnsSign = true;
		// } else {
		// 	this.isSnsSign = false;
		// }
		this.isSnsSign = true;
	},
	computed: {
		getSnsType() {
			switch (this.snsType.toUpperCase()) {
				case 'NAVER':
					return '네이버';
				case 'KAKAO':
					return '카카오';
				default:
					return '정해지지 않은 SNS TYPE';
			}
		},
	},
	methods: {
		handleClose() {
			location.href = '/signup';
		},
		sns(snsType) {
			if (snsType === 'kakao') {
				return;
			}
			const href =
				this.backUrl + '/oauth2/authorization/' + snsType + '?redirect_uri=' + this.frontUrl + '/oauth/redirect/signup';
			location.href = href;
		},
		handleComplete(e) {
			this.handleCompleteFromParent(e);
		},
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/login.css';
</style>
