<template>
	<Fragment>
		<!-- 가입 약관 동의 -->
		<h3 class="heading-3-b mt-64 blind">가입 약관 동의</h3>
		<div class="policy-card">
			<!-- 모두 선택 -->
			<div class="group-chk title">
				<input @click="handleCheckAll" v-model="isCheckAll" type="checkbox" id="checkAll" />
				<label for="checkAll" class="label">서비스 약관에 모두 동의합니다.</label>
			</div>
			<ul class="check-list">
				<!-- 필수 선택 -->
				<li v-for="termsDetail in getRequiredTermsList" class="group-chk" :key="termsDetail.termsId">
					<input
						:checked="termsDetail.agreed"
						type="checkbox"
						:id="termsDetail.termsId"
						@click="
							termsDetail.agreed = !termsDetail.agreed;
							checkTermsAgreement();
						"
					/>
					<label :for="termsDetail.termsId" class="label">
						<span class="text-caution">(필수)</span>
						{{ termsDetail.termsName }}
					</label>
					<button @click="openTermsLayer(termsDetail.termsId, termsDetail.termsName)" class="ghost mini more">
						내용보기
					</button>
				</li>
			</ul>
			<ul class="check-list">
				<li v-for="termsDetail in getNotRequiredTermsList" class="group-chk" :key="termsDetail.termsId">
					<input
						@click="
							termsDetail.agreed = !termsDetail.agreed;
							checkTermsAgreement();
							handleCheckAllMarketing($event);
						"
						type="checkbox"
						:id="termsDetail.termsId"
						v-model="termsDetail.agreed"
					/>
					<label :for="termsDetail.termsId" class="label">
						<span class="label">(선택)</span>
						{{ termsDetail.termsName }}
					</label>
					<button @click="openTermsLayer(termsDetail.termsId, termsDetail.termsName)" class="ghost mini more">
						내용보기
					</button>
				</li>
			</ul>
		</div>
	</Fragment>
</template>

<script>
import { getTermDetail } from '@/api/customer';
import termsTemplate from '@/components/login/TermsDetailLayer';
import { TERMS_MODAL_STYLE } from '@/utils/constants';

export default {
	created() {
		this.termsList = this.termsListProps.map((termInfo) => {
			return { ...termInfo };
		});
	},
	props: ['termsListProps'],
	data() {
		return {
			isCheckAll: false,
			isRequiredAllChecked: false,
			termsList: [],
			renderKey: 0,
		};
	},
	watch: {
		termsList: {
			handler(val) {
				this.updateParentTermsData();
			},
			deep: true,
		},
	},
	computed: {
		getRequiredTermsList() {
			return this.termsList.filter((termsDetail) => termsDetail.required);
		},
		getNotRequiredTermsList() {
			return this.termsList.filter((termsDetail) => !termsDetail.required);
		},
	},
	methods: {
		handleCheckAll(e) {
			let isCheck = e.currentTarget.checked;
			this.termsList.forEach((termsDetail) => (termsDetail.agreed = isCheck));
			this.checkTermsAgreement();
		},
		checkTermsAgreement() {
			const requiredFilter = this.termsList
				.filter((termsDetail) => termsDetail.required)
				.filter((termsDetail) => !termsDetail.agreed);
			this.isRequiredAllChecked = requiredFilter.length === 0;
			const notRequiredFilter = this.termsList
				.filter((termsDetail) => !termsDetail.required)
				.filter((termsDetail) => !termsDetail.agreed);
			this.isCheckAll = this.isRequiredAllChecked && notRequiredFilter.length === 0;
		},
		async openTermsLayer(termsId, termsName) {
			await getTermDetail(termsId).then((response) => {
				this.$modal.show(
					termsTemplate,
					{
						termsTitle: termsName,
						termsContent: response.data.termsContent,
					},
					Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }),
				);
			});
		},
		handleCheckAllMarketing(e) {
			let isCheck = e.currentTarget.checked;
			console.log(e);
		},
		updateParentTermsData() {
			const termsData = {
				termsList: this.termsList,
				isRequiredAllChecked: this.isRequiredAllChecked,
				isCheckAll: this.isCheckAll,
			};
			this.$emit('updateTermsData', termsData);
		},
	},
};
</script>

<style></style>
