<template>
	<Fragment>
		<div class="tab-contents">
			<div class="additional body-3-r">* 신규 주문과 서비스 개설은 <em>기업회원만 가능</em>합니다.</div>
			<!-- <h3 class="heading-3-b mt-64">가입 형태 선택</h3> -->
			<!-- 가입 형태 선택 -->
			<!-- tab -->
			<!-- <div class="tab-wrap">
				<ul class="tab-items">
					<li :class="[signupType === 'normal' ? 'tab on' : 'tab']" @click="changeSignupType('normal')">
						<a id="testTab_01">
							<span class="tab-text">일반 가입</span>
						</a>
					</li>
					<li :class="[signupType === 'sns' ? 'tab on' : 'tab']" @click="changeSignupType('sns')">
						<a id="testTab_02">
							<span class="tab-text">소셜 회원 가입</span>
						</a>
					</li>
				</ul> -->

			<!-- 일반 가입 컨텐츠 v-if="signupType === 'normal'"-->
			<div class="group column">
				<div class="group-inp">
					<label for="testText09" class="label asterisk"> 담당자 인증 </label>
					<!-- 인증 전 *** -->
					<div class="group row">
						<IdentityVerificationButton
							code-type="signup"
							:className="'line mobile icon-text'"
							:title="'휴대폰 인증'"
							:isSnsSign="isSnsSign"
							:hasIcon="true"
							v-on:complete="handleComplete"
							ref="phoneAuthentication"
							@clearAuth="clearAuthInfo"
						></IdentityVerificationButton>

						<!--  v-if="signupType === 'sns'"-->
						<button class="solid naver icon" id="snsAuthentication" ref="snsAuthentication" @click="snsAuth('naver')">
							<i class="ic-32 naver"></i>
							네이버 인증
						</button>
					</div>
				</div>
			</div>
			<div class="group-inp">
				<p v-if="authenticationReason" class="text-detail" :class="[isInvalidAuthentication ? 'invalid' : 'valid']">
					<i class="ic-16" :class="[isInvalidAuthentication ? 'ic-invalid' : 'ic-valid']"></i>{{ authenticationReason }}
				</p>
			</div>
			<!-- 회원 정보 -->
			<div class="group column" :class="{ blind: completedAuth }">
				<!-- 본인 인증 이후, UI : 인증이 되었을 떄만 노출 -->
				<!-- 이름 -->
				<div class="group-inp">
					<label class="label"> 이름 </label>
					<input
						type="text"
						v-model="name"
						value=""
						class="disabled"
						disabled=""
						placeholder="본인 인증을 진행해주세요."
					/>
				</div>
				<!-- 생년월일 -->
				<div class="group-inp">
					<label class="label"> 생년월일 </label>
					<input type="text" v-model="birthday" value="" disabled placeholder="본인 인증을 진행해주세요." />
				</div>
				<!-- // 본인 인증 이후, UI : 인증이 되었을 떄만 노출 -->
				<!-- 이메일 -->
				<div class="group-inp" :class="{ invalid: isInvalidEmail }">
					<label class="label asterisk">
						<span>이메일 </span>
						<i class="ic-16 ic-info"
							>정보
							<div class="tooltip-layer bottom">
								SNS 회원가입을 진행하시는 경우, 해당 SNS에 등록된 기본 이메일 정보로 입력됩니다. 변경을 원하실 경우,
								주로 사용하는 이메일 주소로 수정해 주세요.
							</div>
						</i></label
					>
					<input
						v-model="email"
						v-on:input="validateEmail"
						v-on:blur="validateDuplicateEmail"
						ref="email"
						type="email"
						id="email"
						maxlength="50"
						value=""
						placeholder="인증을 진행할 이메일을 입력해주세요."
					/>
				</div>
				<p v-if="emailReason" class="text-detail" :class="[isInvalidEmail ? 'invalid' : 'valid']">
					<i class="ic-16" :class="[isInvalidEmail ? 'ic-invalid' : 'ic-valid']"></i>{{ emailReason }}
				</p>
			</div>
			<!-- 가입 약관 동의 -->
			<TermsInfoItem
				:termsListProps="termsList"
				:key="`${termsComponentKey.prefix}${termsComponentKey.idx}`"
				@updateTermsData="updateTermsData"
				ref="termsComponent"
			></TermsInfoItem>
		</div>
		<!-- // 일반 가입 컨텐츠 -->
		<div class="btn-area apply text-center">
			<button class="solid" :class="{ disabled: completedValidation }" @click="handleSignup" :disabled="disabledButton">
				동의 및 가입하기
			</button>
		</div>
	</Fragment>
</template>

<script>
import {
	duplicationEmail,
	findId,
	resetPassword,
	signup,
	signupSns,
	signupSnsWithLink,
	signupNormalWithLink,
} from '@/api/login';
import { checkEmail, checkLoginId } from '@/plugins/custom/modules/validator';
import { SIGNUP_MODAL_STYLE, TERMS_MODAL_STYLE } from '@/utils/constants';
import { setInviteCodeCookie, setDaouofficeCodeCookie } from '@/utils/cookies';
import mixinsAlert from '@/components/common/mixinsAlert';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';
import termsTemplate from '@/components/login/TermsDetailLayer';
import {
	ALREADY_SIGNED,
	VALID_EMAIL,
	ALREADY_USED_EMAIL,
	CHECK_EMAIL,
	INVALID_EMAIL,
	INVALID_PASSWORD_LENGTH_CHARACTER,
	PASSWORD_MATCH_FAIL,
	SEND_EMAIL_WAITING,
	INVALID_LOGINID,
	AUTHENTICATION_CONFIRM_FAIL,
	AUTHENTICATION_CONFIRM_SUCCESS,
	REQUIRED_CHECKED_TERM_FAIL,
	OTHER_CUSTOMER_SUPER_ADMIN,
	NOT_ALLOW_SIGNUP_EMAIL,
	INVALID_PASSWORD_CONTENT,
} from '@/temlplates';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import { validation } from '@/components/apply/mixinsValidation';
import _ from 'lodash';
import TermsInfoItem from '@/components/login/TermsInfoItem';
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import { getOpenLoungeeCode } from '@/utils/cookies';
import SignupLayer from '@/components/login/SignupLayer';
import { mapGetters } from 'vuex';
import { password_validation } from '@/utils/utils';

export default {
	components: {
		TermsInfoItem,
		IdentityVerificationButton,
	},
	mixins: [mixinsAlert, mixinsErrCodeObserver, validation, mixinLinkAccount],
	props: {
		snsEmail: { type: String, default: '' },
		snsProfileUrl: { type: String, default: '' },
		snsName: { type: String, default: '다우' },
		snsType: { type: String, default: '' },
		snsId: { type: String, default: '' },
		snsCi: { type: String, default: '' },
		updateLoginInfo: { type: Object, default: null },
	},
	data() {
		return {
			signupType: 'normal',
			email: '',
			profileUrl: '',
			sns: {
				ci: '',
				id: '',
				type: '',
			},
			name: '',
			loginId: '',
			password: '',
			passwordConfirm: '',
			ci: '',
			birthday: '',
			phoneNumber: '',
			rank: '',
			tel: '',
			mfaDevice: '',
			step: 0,
			maxStep: 0,
			serviceTermAgree: false,
			privacyUseAgree: false,
			overYearsOld: false,
			emailReceiveAgree: false,
			smsReceiveAgree: false,
			isInvalidEmail: null,
			isInvalidLoginId: null,
			isInvalidPassword: null,
			isInvalidPasswordConfirm: null,
			isInvalidAuthentication: null,
			emailReason: null,
			loginIdReason: null,
			passwordReason: null,
			passwordConfirmReason: null,
			authenticationReason: null,
			useSecondFactor: false,
			isReSent: false,
			isSnsSign: false,
			isRequiredAllChecked: false,
			isCheckAll: false,
			termsList: [],
			waitOneMinute: true,
			termsComponentKey: {
				prefix: 'terms',
				idx: 0,
			},
			loginComponentKey: {
				prefix: 'login',
				idx: 0,
			},
			lKey: 0,
			linkCode: false,
			backUrl: process.env.VUE_APP_API_URL,
			frontUrl: process.env.VUE_APP_FRONT_SERVER,
			canSendSignupEmail: true,
			funcSignupWithLink: {
				async sns(data) {
					return await signupSnsWithLink(data);
				},
				async normal(data) {
					return await signupNormalWithLink(data);
				},
			},
			hasNormalLinkCode: this.$store.getters['hasNormalLinkCode'],
			completedAuth: 'blind',
			completedValidation: 'disabled',
			disabledButton: true,
		};
	},
	created() {
		this.setLoginInfo(this.updateLoginInfo.loginInfo, this.updateLoginInfo.loginInvalidClass);
		this.setTerms();
		this.setDataBySnsRedirect();
		this.setSnsInfoByProps();
		if (this.sns.id && this.sns.type) {
			this.openSignupLayer();
		}
	},
	destroyed() {
		this.$emit('clearSns');
	},
	computed: {
		getRequiredTermsList() {
			return this.termsList.filter((termsDetail) => termsDetail.required);
		},
		getNotRequiredTermsList() {
			return this.termsList.filter((termsDetail) => !termsDetail.required);
		},
		getTermsKey() {
			return this.termsComponentKey.prefix + this.termsComponentKey.idx;
		},
		getLoginKey() {
			return this.loginComponentKey.prefix + this.termsComponentKey.idx;
		},
		isEmailInvalid() {
			return !this.email || this.isInvalidEmail;
		},
		isLoginIdInvalid() {
			return !this.loginId || this.isInvalidLoginId;
		},
		isPasswordInvalid() {
			this.validatePasswordByIdentity();
			return !this.password || this.isInvalidPassword;
		},
		isPasswordConfirmInvalid() {
			return !this.passwordConfirm || this.isInvalidPasswordConfirm || this.passwordConfirm !== this.password;
		},
		isAuthenticationInvalid() {
			return !this.ci;
		},
		isRequiredAllCheckedInvalid() {
			return !this.isRequiredAllChecked;
		},
		hasLinkCode() {
			return this.$store.getters['getDaouofficeCode'] || this.$store.getters['getInviteCode'] || getOpenLoungeeCode();
		},
	},
	methods: {
		async setTerms() {
			await this.$store.dispatch('setCustomerTermsList');
			this.termsList = this.$store.getters.getCustomerTermsList;
			this.termsComponentKey.idx += 1;
		},
		async handleComplete(data) {
			this.$emit('setSnsSign', this.isSnsAuthentication());

			this.$modal.hide('signup');
			if (this.sns.ci && this.sns.ci !== data.ci) {
				this.clearSnsAuthInfo();
				this.clearAuthInfo();
				this.$toasted.show('소셜 인증 정보와 휴대폰 본인 정보가 맞지 않습니다.');
				return;
			}
			this.name = data.name;
			this.ci = data.ci;
			this.phoneNumber = data.phoneNumber;
			this.birthday = data.birthday;
			this.email = data.email;
			this.completedAuth = '';

			const managerInfo = {
				name: this.name,
				phoneNumber: this.phoneNumber,
				birthDay: this.birthday,
				email: this.email,
				ci: this.ci,
			};
			this.$emit('updateManagerInfo', managerInfo);

			try {
				const response = await findId({
					name: this.name,
					ci: this.ci,
					phoneNumber: this.phoneNumber,
					birthDay: this.birthday,
					fromSignup: true,
					type: 'individual',
				});
				const account = response.data[0];
				this.email = account.email;
				this.loginId = account.loginId;

				this.disableCloseConfirmEmit();
				this.alert({
					title: '회원가입',
					contents: ALREADY_SIGNED(this.name, this.email, this.loginId),
					buttons: [
						{
							text: '비밀번호 초기화',
							callback: () => {
								this.passwordReset(account);
							},
						},
						{
							text: '로그인',
						},
					],
				});
				await this.$router.push('/login');
			} catch (e) {
				// this.moveToStep(1);
				this.validateEmail();
				await this.validateDuplicateEmail();
				if (e.data?.errorCode !== 'ERROR212' && e.data?.errorCode !== 'ERROR0202') {
					// this.$emit('isVerified', true);
					this.$toasted.show(e.data?.errorMessage);
				} else {
					this.isInvalidAuthentication = false;
					this.authenticationReason = AUTHENTICATION_CONFIRM_SUCCESS;
				}
			}
		},
		getValidateCheckList() {
			return [
				{
					check: this.isAuthenticationInvalid,
					focus: () => {
						const refs =
							this.signupType === 'normal'
								? this.$refs.phoneAuthentication.$refs.authButton
								: this.$refs.snsAuthentication;
						refs.focus();
						this.isInvalidAuthentication = true;
						this.authenticationReason = AUTHENTICATION_CONFIRM_FAIL;
					},
				},
				{
					check: this.isEmailInvalid,
					focus: () => {
						this.$refs.email.focus();
						this.isInvalidEmail = true;
						this.emailReason = NOT_ALLOW_SIGNUP_EMAIL;
					},
				},
				{
					check: this.isLoginIdInvalid,
					focus: () => {
						this.loginIdReason = INVALID_LOGINID;
						this.isInvalidLoginId = true;
						this.$refs.loginComponent.$refs.loginId.focus();
					},
				},
				{
					check: this.isPasswordInvalid && !this.isSnsSign,
					focus: () => {
						this.isInvalidPassword = true;
						this.passwordReason = this.passwordReason || INVALID_PASSWORD_LENGTH_CHARACTER;
						this.$refs.loginComponent.$refs.password.focus();
					},
				},
				{
					check: this.isPasswordConfirmInvalid && !this.isSnsSign,
					focus: () => {
						this.$refs.loginComponent.$refs.passwordConfirm.focus();
						this.isInvalidPasswordConfirm = true;
						this.passwordConfirmReason = PASSWORD_MATCH_FAIL;
					},
				},
				{
					check: this.isRequiredAllCheckedInvalid,
					focus: () => {
						this.$toasted.show(REQUIRED_CHECKED_TERM_FAIL);
					},
				},
			];
		},
		allValidationCheck() {
			const checkList = this.getValidateCheckList();

			for (let i in checkList) {
				if (checkList[i].check) {
					checkList[i].focus();
					break;
				}
			}
			this.loginComponentKey.idx += 1;

			const isInvalidPasswordAndPasswordConfirm = this.isSnsSign
				? true
				: !this.isInvalidPassword && !this.isInvalidPasswordConfirm;
			return (
				!this.isInvalidLoginId &&
				!this.isInvalidEmail &&
				isInvalidPasswordAndPasswordConfirm &&
				this.isRequiredAllChecked &&
				!this.isInvalidAuthentication
			);
		},
		async handleSignup() {
			const isValidate = this.allValidationCheck();
			if (!isValidate) {
				console.log('회원가입 실패');
				return;
			}

			// TODO: 약관 정리
			const agreeResult = [];
			for (let i = 0; i < this.termsList.length; i++) {
				agreeResult.push({
					termsId: this.termsList[i].termsId,
					agreed: this.termsList[i].agreed,
					required: this.termsList[i].required,
				});
			}

			const signupData = {
				name: this.name,
				loginId: this.loginId,
				email: this.email,
				profileUrl: this.profileUrl,
				password: this.password,
				ci: this.ci,
				birthDay: this.birthday,
				phoneNumber: this.phoneNumber,
				snsId: this.sns.id,
				snsType: this.sns.type,
				accountTwoFactorType: this.useSecondFactor ? 'EMAIL' : 'NONE',
				agreementList: agreeResult,
			};

			try {
				if (this.isSnsSign) {
					await this.signupSns(signupData);
				} else {
					await this.signupNormal(signupData);
				}
			} catch (response) {
				if (response.status !== 200) {
					this.observeErrCode(response.data?.errorCode, response.data?.errorMessage, this.$toasted.show);
					// this.$ms.toast.show(response.data.errorMessage);
				}
			}
		},
		async signupSns(signupData) {
			let response = null;
			if (this.hasNormalLinkCode) {
				response = await this.signupWithLinkCode(signupData, 'sns');
			} else {
				response = await signupSns(signupData);
			}
			this.disableCloseConfirmEmit();
			await this.$router.replace({ name: 'SignUpSuccess', query: { name: response.data.name } });
		},
		async signupNormal(signupData) {
			if (this.hasNormalLinkCode) {
				const response = await this.signupWithLinkCode(signupData, 'normal');
				this.disableCloseConfirmEmit();
				await this.$router.replace({ name: 'SignUpSuccess', query: { name: response.data.name } });
			} else {
				await signup(signupData);
				this.updateConfirmSigninParam();
			}
		},
		async signupWithLinkCode(signupData, type = '') {
			if (!type) {
				this.callSignupFailAlert();
			}
			const daouofficeCode = this.$store.getters['getDaouofficeCode'];
			const inviteCode = this.$store.getters['getInviteCode'];
			this.clearLinkCodeInStore();
			if (daouofficeCode || inviteCode) {
				try {
					Object.assign(signupData, { daouofficeCode, inviteCode });
					return await this.funcSignupWithLink[type](signupData);
				} catch (e) {
					this.callSignupFailAlert();
				}
			}
		},
		callSignupFailAlert() {
			this.disableCloseConfirmEmit();
			this.alert({
				contents: '초대 코드 회원가입이 불가능합니다. 다우오피스 관리자에 문의 부탁드립니다.',
				buttons: [
					{
						text: '로그인으로',
						callback: () => {
							this.$store.commit('CLEAR_LINK_CODES');
							this.$router.replace('/login');
						},
					},
					{
						text: '일반회원가입',
						callback: () => {
							this.$store.commit('CLEAR_LINK_CODES');
							this.$emit('clearLinkCode');
						},
					},
				],
			});
		},
		disableCloseConfirmEmit() {
			this.$emit('disableCloseCorifirm');
		},
		async validateDuplicateEmail() {
			if (_.isEmpty(this.email)) {
				return;
			}
			let response = await duplicationEmail(this.email);
			if (response.data) {
				this.isInvalidEmail = true;
				this.emailReason = ALREADY_USED_EMAIL;
			}
		},
		validateEmail() {
			if (checkEmail(this.email)) {
				this.isInvalidEmail = false;
				this.emailReason = VALID_EMAIL;
			} else {
				this.isInvalidEmail = true;
				this.emailReason = INVALID_EMAIL;
			}
		},
		async passwordReset(account) {
			try {
				await resetPassword({
					id: account.id,
					email: account.email,
				});

				this.$toasted.show('비밀번호 초기화 메일이 발송되었습니다.');
				this.$emit('close');
			} catch (e) {
				if (e.data.errorCode === 'ERROR210') {
					this.$emit('close');
					return this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.setLoginErrorMessage);
				} else {
					this.$toasted.show('비밀번호 초기화 메일 발송에 실패했습니다. 다시 시도해주세요');
				}
			}
		},
		setLoginInfo(loginData, loginValidData) {
			this.loginId = loginData.loginId;
			this.password = loginData.password;
			this.passwordConfirm = loginData.passwordConfirm;
			this.isInvalidLoginId = loginValidData.loginId;
			this.isInvalidPassword = loginValidData.password;
			this.isInvalidPasswordConfirm = loginValidData.passwordConfirm;
		},
		isSnsAuthentication() {
			return this.signupType === 'sns';
		},
		changeSignupType(type) {
			if (type !== this.signupType) {
				this.initAuthInfo();
				this.name = '';
				this.birthday = '';
				this.initEmailInfo();
			}
			this.signupType = type;
		},
		initAuthInfo() {
			this.ci = '';
			this.isInvalidAuthentication = null;
			this.authenticationReason = null;
		},
		initEmailInfo() {
			this.email = '';
			this.emailReason = null;
			this.isInvalidEmail = null;
		},
		snsAuth(snsType) {
			if (snsType === 'kakao') {
				return;
			}
			this.clearSnsAuthInfo();
			this.clearAuthInfo();
			this.changeSignupType('sns');
			this.setLinkCodeInCookie();
			const href =
				this.backUrl + '/oauth2/authorization/' + snsType + '?redirect_uri=' + this.frontUrl + '/oauth/redirect/signup';
			location.href = href;
		},
		clearAuthInfo() {
			this.isInvalidAuthentication = null;
			this.authenticationReason = null;
			this.name = '';
			this.ci = '';
			this.birthday = '';
			this.phoneNumber = '';
			this.initEmailInfo();
		},
		clearSnsAuthInfo() {
			this.sns.ci = '';
			this.sns.id = '';
			this.sns.type = '';
			this.isSnsSign = false;
			this.signupType = 'normal';
		},
		setDataBySnsRedirect() {
			this.signupType = this.snsCi ? 'sns' : 'normal';
			this.isSnsSign = !!this.snsCi;
		},
		updateTermsData(terms) {
			this.termsList = terms.termsList;
			this.isRequiredAllChecked = terms.isRequiredAllChecked;
			this.isCheckAll = terms.isCheckAll;
		},
		updateUseSecondFactor(factor) {
			this.useSecondFactor = factor;
		},
		updateConfirmSigninParam() {
			this.disableCloseConfirmEmit();
			this.$emit('updateConfirmSigninParam', {
				canSendConfirmEmail: true,
				email: this.email,
				loginId: this.loginId,
				password: this.password,
			});
		},
		async openSignupLayer() {
			if (this.email !== '') {
				this.isSnsSign = true;
				this.useSecondFactor = false;
			}

			this.$modal.show(
				SignupLayer,
				{ name: this.snsName, snsType: this.sns.type, handleCompleteFromParent: this.handleComplete },
				Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'signup', clickToClose: false }),
			);
		},
		clearLinkCodeInStore() {
			this.$store.commit('CLEAR_LINK_CODES');
		},
		setSnsInfoByProps() {
			this.email = this.snsEmail;
			this.profileUrl = this.snsProfileUrl;
			this.sns.ci = this.snsCi;
			this.sns.id = this.snsId;
			this.sns.type = this.snsType;
		},
		setLinkCodeInCookie() {
			const inviteCode = this.$store.getters['getInviteCode'];
			if (inviteCode) {
				setInviteCodeCookie(inviteCode);
			}

			const daouofficeCode = this.$store.getters['getDaouofficeCode'];
			if (daouofficeCode) {
				setDaouofficeCodeCookie(daouofficeCode);
			}
		},
		validatePasswordByIdentity() {
			if (
				password_validation.checkIdentityCharacters(
					this.password,
					this.phoneNumber,
					this.birthday,
					this.email,
					this.loginId,
				)
			) {
				return;
			}
			this.isInvalidPassword = true;
			this.passwordReason = INVALID_PASSWORD_CONTENT;
		},
		allValidationCheckButton() {
			if (
				this.isAuthenticationInvalid ||
				this.isEmailInvalid ||
				this.isLoginIdInvalid ||
				(this.isPasswordInvalid && !this.isSnsSign) ||
				(this.isPasswordConfirmInvalid && !this.isSnsSign) ||
				this.isRequiredAllCheckedInvalid
			) {
				this.completedValidation = 'disabled';
				this.disabledButton = true;
				return;
			}
			this.completedValidation = '';
			this.disabledButton = false;
		},
	},
	watch: {
		email() {
			if (this.email && this.checkEmail(this.email) && this.validateDuplicateEmail()) {
				const managerInfo = {
					name: this.name,
					phoneNumber: this.phoneNumber,
					birthDay: this.birthday,
					email: this.email,
					ci: this.ci,
				};
				this.$emit('updateManagerInfo', managerInfo);
			}
		},
		updateLoginInfo() {
			this.setLoginInfo(this.updateLoginInfo.loginInfo, this.updateLoginInfo.loginInvalidClass);
		},
		isAuthenticationInvalid() {
			this.allValidationCheckButton();
		},
		isEmailInvalid() {
			this.allValidationCheckButton();
		},
		isLoginIdInvalid() {
			this.allValidationCheckButton();
		},
		isPasswordInvalid() {
			this.allValidationCheckButton();
		},
		isSnsSign() {
			this.allValidationCheckButton();
		},
		isPasswordConfirmInvalid() {
			this.allValidationCheckButton();
		},
		isRequiredAllCheckedInvalid() {
			this.allValidationCheckButton();
		},
	},
};
</script>

<style scoped></style>
