<template>
	<div>
		<!-- 로그인 정보 -->
		<div class="group column">
			<!-- 아이디(ID) -->
			<div class="group-inp" :class="{ invalid: loginInvalidClass.loginId }">
				<label class="label body-2-m asterisk"> 아이디 </label>
				<div class="group-inp-btn" id="testButtonInput">
					<input
						v-model="loginInfo.loginId"
						v-on:input="validateLoginId"
						v-on:blur="validateDuplicateLoginId"
						v-on:keyup="setParentLoginInfo"
						ref="loginId"
						type="text"
						id="loginId"
						maxlength="64"
						value=""
						placeholder="3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 만 입력해야 합니다."
					/>
				</div>
				<p
					v-if="loginInvalidClass.loginIdReason"
					class="text-detail"
					:class="[loginInvalidClass.loginId ? 'invalid' : 'valid']"
				>
					<i class="ic-16" :class="[loginInvalidClass.loginId ? 'ic-invalid' : 'ic-valid']"></i
					>{{ loginInvalidClass.loginIdReason }}
				</p>
			</div>
			<!-- 비밀번호 : 일반 가입일때만 사용. 소셜 가입일때는 비밀번호 영역 없음 -->
			<div class="group column">
				<div v-if="!this.isSnsSign" class="group-inp rgt-ic-16" :class="{ invalid: loginInvalidClass.password }">
					<label class="label body-2-m asterisk"> 비밀번호 </label>
					<input
						v-model="loginInfo.password"
						ref="password"
						v-on:input="validatePassword"
						v-on:keyup="setParentLoginInfo"
						type="password"
						maxlength="50"
						value=""
						placeholder="8자 이상의 영문자, 숫자, 특수문자(공백 제외)를 조합하여 사용하세요."
					/>
					<p
						v-if="loginInvalidClass.passwordReason"
						class="text-detail"
						:class="[loginInvalidClass.password ? 'invalid' : 'valid']"
					>
						<i class="ic-16" :class="[loginInvalidClass.password ? 'ic-invalid' : 'ic-valid']"></i
						>{{ loginInvalidClass.passwordReason }}
					</p>
				</div>
				<div
					v-if="!this.isSnsSign"
					class="group-inp rgt-ic-16 mt-8"
					:class="{ invalid: loginInvalidClass.passwordConfirm }"
				>
					<label class="label body-2-m asterisk">비밀번호 확인</label>
					<input
						v-model="loginInfo.passwordConfirm"
						ref="passwordConfirm"
						v-on:input="validatePasswordConfirm"
						v-on:keyup="setParentLoginInfo"
						type="password"
						value=""
						placeholder="비밀번호를 다시 입력해주세요."
					/>

					<!-- invalid message -->
					<p
						v-if="loginInvalidClass.passwordConfirmReason"
						class="text-detail"
						:class="[loginInvalidClass.passwordConfirm ? 'invalid' : 'valid']"
					>
						{{ loginInvalidClass.passwordConfirmReason }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { checkLoginId } from '@/plugins/custom/modules/validator';
import {
	ALREADY_USED_LOGINID,
	INVALID_LOGINID,
	INVALID_PASSWORD_CONTENT,
	INVALID_PASSWORD_LENGTH_CHARACTER,
	PASSWORD_MATCH,
	PASSWORD_MATCH_FAIL,
	VALID_LOGINID,
	VALID_PASSWORD,
} from '@/temlplates';
import { duplicationLoginId } from '@/api/login';
import { password_validation } from '@/utils/utils';

export default {
	props: {
		loginInfoProps: { type: Object },
		loginInvalidClassProps: { type: Object },
		isSnsSign: { type: Boolean, default: false },
		isCustomer: { type: Boolean, default: false },
	},
	created() {
		this.loginInfo = { ...this.loginInfoProps };
		this.loginInvalidClass = { ...this.loginInvalidClassProps };
	},
	data() {
		return {
			loginInfo: {},
			loginInvalidClass: {},
			useSecondFactor: false,
		};
	},
	methods: {
		validateLoginId(e) {
			if (_.isEmpty(e.currentTarget.value) || checkLoginId(e.currentTarget.value)) {
				this.loginInvalidClass.loginIdReason = INVALID_LOGINID;
				this.loginInvalidClass.loginId = true;
			} else {
				this.loginInvalidClass.loginIdReason = VALID_LOGINID;
				this.loginInvalidClass.loginId = false;
			}
		},
		validateDuplicateLoginId(e) {
			if (_.isEmpty(e.currentTarget.value)) {
				this.loginInvalidClass.loginIdReason = INVALID_LOGINID;
				this.loginInvalidClass.loginId = true;
				return;
			}
			duplicationLoginId(e.currentTarget.value).then((response) => {
				if (response.data) {
					this.loginInvalidClass.loginIdReason = ALREADY_USED_LOGINID;
					this.loginInvalidClass.loginId = true;
				}
			});
		},
		validatePassword(e) {
			const password = typeof e === 'string' ? e : e.currentTarget.value;
			if (!password_validation.checkLengthAndCharacter(password)) {
				this.loginInvalidClass.password = true;
				this.loginInvalidClass.passwordReason = INVALID_PASSWORD_LENGTH_CHARACTER;
			} else if (
				!password_validation.checkIdentityCharacters(
					password,
					this.loginInfoProps.phoneNumber,
					this.loginInfoProps.birthday,
					this.loginInfoProps.email,
					this.loginInfo.loginId,
				) ||
				!password_validation.checkRepeatedCharacters(password) ||
				!password_validation.checkConsecutiveCharacters(password)
			) {
				this.loginInvalidClass.password = true;
				this.loginInvalidClass.passwordReason = INVALID_PASSWORD_CONTENT;
			} else {
				this.loginInvalidClass.password = false;
				this.loginInvalidClass.passwordReason = VALID_PASSWORD;
			}

			if (!_.isEmpty(this.loginInfo.passwordConfirm)) {
				this.validatePasswordConfirm();
			}
		},
		validatePasswordConfirm() {
			if (this.loginInfo.password === this.loginInfo.passwordConfirm) {
				this.loginInvalidClass.passwordConfirm = false;
				this.loginInvalidClass.passwordConfirmReason = PASSWORD_MATCH;
			} else {
				this.loginInvalidClass.passwordConfirm = true;
				this.loginInvalidClass.passwordConfirmReason = PASSWORD_MATCH_FAIL;
			}
		},
		setParentLoginInfo() {
			this.$emit('setLoginInfo', this.loginInfo, this.loginInvalidClass);
		},
	},
	watch: {
		loginInfoProps() {
			if (
				!this.isSnsSign &&
				(this.loginInfoProps.email || this.loginInfoProps.birthday || this.loginInfoProps.phoneNumber)
			) {
				this.validatePassword(this.loginInfoProps.password);
			}
		},
	},
};
</script>

<style></style>
