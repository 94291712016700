<template>
	<Fragment>
		<h3 class="heading-3-b mt-64 blind" v-if="!isDomsInvite">기업정보</h3>
		<!-- 기업정보 -->
		<div class="group column" v-if="!isDomsInvite">
			<!-- 상호 -->
			<div class="group-inp" :class="companyInvalidClass.corporateName">
				<label class="label asterisk">상호(법인명)</label>
				<input
					id="corporateName"
					type="text"
					placeholder="상호명을 입력해주세요."
					v-model.trim="companyInfo.corporateName"
					maxlength="64"
					@input="validateCorporateName"
					ref="corporateName"
				/>
			</div>
			<p class="text-detail invalid" v-if="companyInvalidClass.corporateName">
				<i class="ic-16 ic-invalid"></i>
				{{ requiredMessage(companyInfo.corporateName, '상호(법인)명 64자 이하로 입력해주세요.') }}
			</p>
			<div class="group-inp" :class="companyInvalidClass.ceoName">
				<label class="label asterisk">대표자명</label>
				<input
					id="ceoName"
					type="text"
					placeholder="대표자명을 입력해주세요."
					v-model.trim="companyInfo.ceoName"
					maxlength="100"
					@input="validateCeoName"
					ref="ceoName"
				/>
			</div>
			<p class="text-detail invalid" v-if="companyInvalidClass.ceoName">
				<i class="ic-16 ic-invalid"></i>
				{{ requiredMessage(companyInfo.ceoName, '대표자명을 2자 이상, 100자 이하로 입력해주세요.') }}
			</p>
			<!-- 사업자등록번호 -->
			<div class="group-inp">
				<label class="label asterisk">
					사업자등록번호
					<!-- help -->
					<i class="ic-16 ic-info">
						<!-- @TODO: 사업부와 확인이 필요한 부분으로, 기존 회사법인정보의 태그를 가져옴-->
						<div class="tooltip-layer left">
							사업자등록번호, 개업일자, 대표자성명은 사업자 진위 확인 필수값입니다. 반드시 입력 후 진위 확인을 하세요.
						</div>
					</i>
				</label>
				<div class="group-inp-btn" :class="companyInvalidClass.businessLicenseNum" id="testButtonInput">
					<input
						id="businessLicenseNum"
						type="text"
						placeholder="0000000000"
						maxlength="10"
						v-model.trim="companyInfo.businessLicenseNum"
						@input="validateBusinessLicenseNumber"
						oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
						@keyup="reCheckConfirmedLicense"
						ref="businessLicenseNumber"
					/>
					<button class="line" @click="checkBusinessLicenseNumber">가입여부 확인</button>
				</div>
				<!-- invalid message -->
				<p class="text-detail invalid" v-if="companyInvalidClass.businessLicenseNum">
					<i class="ic-16 ic-invalid"></i>
					{{ requiredMessage(companyInfo.businessLicenseNum, companyInvalidClass.businessLicenseNumReason) }}
				</p>
				<p class="text-detail invalid" v-if="needToConfirmBusinessLicensenum">
					<i class="ic-16 ic-invalid"></i>
					{{ requiredMessage(companyInfo.businessLicenseNum, companyInvalidClass.businessLicenseNumReason) }}
				</p>
				<p class="text-detail valid" v-if="isConfirmedLicense">
					<i class="ic-16 ic-valid"></i>
					사업자 진위 확인이 완료되었습니다.
				</p>
			</div>
		</div>

		<!-- 담당자 정보 -->
		<div class="group column">
			<div class="group-inp">
				<label for="testText09" class="label asterisk"> 담당자 인증 </label>
				<div class="group row">
					<IdentityVerificationButton
						code-type="signup"
						:className="'line mobile icon-text'"
						:title="'휴대폰 인증'"
						v-on:complete="handleComplete"
						@clearAuth="clearAuthInfo"
						:hasIcon="true"
						ref="phoneAuthentication"
					></IdentityVerificationButton>
				</div>
				<!-- invalid message -->
				<p
					class="text-detail"
					v-if="managerInvalidClass.ciReason"
					:class="managerInvalidClass.ci ? 'invalid' : 'valid'"
				>
					<i class="ic-16" :class="managerInvalidClass.ci ? 'ic-invalid' : 'ic-valid'"></i>
					{{ managerInvalidClass.ciReason }}
				</p>
			</div>
		</div>
		<div class="group column" :class="{ blind: completedAuth }">
			<!-- 휴대전화 -->
			<div class="group-inp">
				<label class="label asterisk">휴대전화</label>
				<div class="group-inp-btn" id="testButtonInput">
					<input type="text" placeholder="본인 인증이 필요합니다." v-model="managerInfo.phoneNumber" disabled />
				</div>
			</div>
			<!-- 본인 인증 이후, UI : 인증이 되었을 떄만 노출 -->
			<!-- 이름 -->
			<div class="group-inp">
				<label class="label"> 이름 </label>
				<input type="text" placeholder="본인 인증이 필요합니다." disabled v-model="managerInfo.name" />
			</div>
			<!-- 생년월일 -->
			<div class="group-inp">
				<label class="label"> 생년월일 </label>
				<input type="text" placeholder="본인 인증이 필요합니다." disabled v-model="managerInfo.birthDay" />
			</div>
			<!-- 이메일 -->
			<div class="group-inp" :class="{ invalid: managerInvalidClass.email }">
				<label class="label asterisk"> 이메일 </label>
				<input
					v-model="managerInfo.email"
					v-on:input="validateEmail"
					v-on:blur="validateDuplicateEmail"
					ref="email"
					type="email"
					id="email"
					maxlength="50"
					value=""
					placeholder="이메일을 입력 해주세요."
				/>
				<p
					v-if="managerInvalidClass.emailReason"
					class="text-detail"
					:class="[managerInvalidClass.email ? 'invalid' : 'valid']"
				>
					<i class="ic-16" :class="[managerInvalidClass.email ? 'ic-invalid' : 'ic-valid']"></i
					>{{ managerInvalidClass.emailReason }}
				</p>
			</div>
			<!-- 직급 -->
			<div class="group-inp">
				<label class="label"> 직급 </label>
				<input v-model="managerInfo.rank" type="text" value="" maxlength="50" placeholder="직급을 입력해주세요." />
			</div>
			<!-- 유선전화 -->
			<div class="group-inp">
				<label class="label"> 유선전화 </label>
				<input
					v-model="managerInfo.tel"
					type="text"
					oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
					value=""
					maxlength="15"
					placeholder="사용하시는 유선전화 번호를 입력해주세요."
				/>
			</div>
		</div>
		<!-- 가입 약관 동의-->
		<TermsInfoItem
			:termsListProps="termsList"
			:key="`${termsComponentKey.prefix}${termsComponentKey.idx}`"
			@updateTermsData="updateTermsData"
			ref="termsComponent"
		></TermsInfoItem>
		<div class="btn-area apply text-center">
			<button class="solid" :class="{ disabled: completedValidation }" @click="handleSignup" :disabled="disabledButton">
				동의 및 가입하기
			</button>
		</div>
	</Fragment>
</template>

<script>
import { duplicationEmail, signupCustomer } from '@/api/login';
import { checkEmail } from '@/plugins/custom/modules/validator';
import { DEFAULT_MODAL_STYLE, businessLicenseNumFailMessage } from '@/utils/constants';
import {
	VALID_EMAIL,
	INVALID_EMAIL,
	INVALID_PASSWORD_LENGTH_CHARACTER,
	PASSWORD_MATCH_FAIL,
	INVALID_LOGINID,
	AUTHENTICATION_CONFIRM_FAIL,
	REQUIRED_CHECKED_TERM_FAIL,
	AUTHENTICATION_CONFIRM_SUCCESS,
	INVALID_BUSINESSLICENSENUM,
	DUPLICATE_BUSINESSLICENSENUM,
	NEED_TO_CONFIRM_BUSINESSLICENSENUM,
	ALREADY_USED_EMAIL,
	INVALID_PASSWORD_CONTENT,
} from '@/temlplates';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import { validation } from '@/components/apply/mixinsValidation';
import _ from 'lodash';
import CommonAlert from '@/components/common/CommonAlert';
import TermsInfoItem from '@/components/login/TermsInfoItem';
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import { password_validation } from '@/utils/utils';

export default {
	components: { TermsInfoItem, IdentityVerificationButton },
	mixins: [validation, mixinsErrCodeObserver],
	created() {
		this.setLoginInfo(this.updateLoginInfo.loginInfo, this.updateLoginInfo.loginInvalidClass);
		this.setTerms();
		this.setCompanyByDomsInvite();
	},
	props: {
		linkCode: { type: String, default: '' },
		isDomsInvite: { type: Boolean, default: false },
		businessLicenseNumProps: { type: String, default: '' },
		corporateNameProps: { type: String, default: '' },
		ceoNameProps: { type: String, default: '' },
		updateLoginInfo: { type: Object, default: null },
	},
	data() {
		return {
			companyInfo: {
				corporateName: '',
				businessLicenseNum: '',
				ceoName: '',
			},
			companyInvalidClass: {
				corporateName: null,
				businessLicenseNum: null,
				ceoName: null,
				businessLicenseNumReason: null,
			},
			loginInfo: {
				loginId: '',
				password: '',
				passwordConfirm: '',
			},
			loginInvalidClass: {
				loginId: null,
				password: null,
				passwordConfirm: null,
				loginIdReason: null,
				passwordReason: null,
				passwordConfirmReason: null,
			},
			managerInfo: {
				phoneNumber: '',
				name: '',
				birthDay: '',
				email: '',
				rank: '',
				tel: '',
				ci: '',
			},
			managerInvalidClass: {
				ci: null,
				ciReason: null,
				email: null,
				emailReason: null,
			},
			useSecondFactor: false,
			isReSent: false,
			isRequiredAllChecked: false,
			isCheckAll: false,
			isConfirmedLicense: false,
			termsList: [],
			waitOneMinute: true,
			termsComponentKey: {
				prefix: 'terms',
				idx: 0,
			},
			loginComponentKey: {
				prefix: 'login',
				idx: 0,
			},
			companyFetchInfo: {
				companyInfo: {
					// 필수값인 경우 invalid가 default, index 순서대로 표현
					corporateName: '',
					ceoName: '',
					repNumber: '',
					businessLicenseNum: '',
					companyAddress: '',
					businessNum: '',
					industryCategory: '',
					businessCondition: '',
					businessType: '',
					employeeCount: '',
					taxInvoiceManagerName: '',
					taxInvoiceEmail: '',
					refundAccountNumber: '',
					bankName: '',
					refundAccountOwnerName: '',
					businessLicenses: [],
					checkedPrivacyPolicy: '',
				},
			},
			completedAuth: 'blind',
			completedValidation: 'disabled',
			disabledButton: true,
		};
	},
	computed: {
		getRequiredTermsList() {
			return this.termsList.filter((termsDetail) => termsDetail.required);
		},
		getNotRequiredTermsList() {
			return this.termsList.filter((termsDetail) => !termsDetail.required);
		},
		getTermsKey() {
			return this.termsComponentKey.prefix + this.termsComponentKey.idx;
		},
		getLoginKey() {
			return this.loginComponentKey.prefix + this.termsComponentKey.idx;
		},
		isCorporateNameInvalid() {
			return !this.companyInfo.corporateName || this.companyInvalidClass.corporateName;
		},
		isBusinessNumberInvalid() {
			return (
				!this.companyInfo.businessLicenseNum || this.companyInvalidClass.businessLicenseNum || !this.isConfirmedLicense
			);
		},
		isEmailInvalid() {
			return !this.managerInfo.email || this.managerInvalidClass.email;
		},
		isLoginIdInvalid() {
			return !this.loginInfo.loginId || this.loginInvalidClass.loginId;
		},
		isPasswordInvalid() {
			this.validatePasswordByIdentity();
			return !this.loginInfo.password || this.loginInvalidClass.password;
		},
		isPasswordConfirmInvalid() {
			return (
				!this.loginInfo.passwordConfirm ||
				this.loginInvalidClass.passwordConfirm ||
				this.loginInfo.passwordConfirm !== this.loginInfo.password
			);
		},
		isAuthenticationInvalid() {
			return !this.managerInfo.ci;
		},
		isRequiredAllCheckedInvalid() {
			return !this.isRequiredAllChecked;
		},
		isCeoNameInvalid() {
			return !this.companyInfo.ceoName || this.companyInvalidClass.ceoName;
		},
		getLoginItemInfo() {
			return {
				loginId: this.loginInfo.loginId,
				password: this.loginInfo.password,
				passwordConfirm: this.loginInfo.passwordConfirm,
				email: this.managerInfo.email,
				birthday: this.managerInfo.birthDay,
				phoneNumber: this.managerInfo.phoneNumber,
			};
		},
		needToConfirmBusinessLicensenum() {
			return (
				this.companyInfo.businessLicenseNum && !this.companyInvalidClass.businessLicenseNum && !this.isConfirmedLicense
			);
		},
	},
	methods: {
		async handleComplete(data) {
			this.managerInfo.name = data.name;
			this.managerInfo.phoneNumber = data.phoneNumber;
			this.managerInfo.birthDay = data.birthday;
			this.managerInfo.email = data.email;
			this.managerInfo.ci = data.ci;
			this.managerInvalidClass.ci = false;
			this.managerInvalidClass.ciReason = AUTHENTICATION_CONFIRM_SUCCESS;
			this.completedAuth = '';
			this.$emit('updateManagerInfo', this.managerInfo);
		},
		validateCorporateName(e) {
			this.companyInvalidClass.corporateName = !(
				this.checkInputLengthTrim(e, 1, 64) && !_.isEmpty(e.target.value.trim())
			);
		},
		validateBusinessLicenseNumber(e) {
			const licenseNumber = this.trimAll(e.target.value);
			this.companyInvalidClass.businessLicenseNum = !this.checkBusinessNumber(licenseNumber);
			this.companyInvalidClass.businessLicenseNumReason = this.needToConfirmBusinessLicensenum
				? NEED_TO_CONFIRM_BUSINESSLICENSENUM
				: INVALID_BUSINESSLICENSENUM;
		},
		trimAll(data) {
			if (!data) {
				return '';
			}
			return data.replaceAll(' ', '');
		},
		reCheckConfirmedLicense() {
			if (this.isConfirmedLicense && this.companyInvalidClass.businessLicenseNum) {
				this.isConfirmedLicense = false;
			}
		},
		async checkBusinessLicenseNumber() {
			if (this.isInvalidatedBusinessLicenseNumber()) {
				this.$toasted.show('사업자 등록번호의 형식에 맞지 않습니다.');
				this.isConfirmedLicense = false;
				return;
			}

			if (this.companyInfo.businessLicenseNum === '9999999999') {
				this.successCheckLicense();
				return;
			}

			await this.$store
				.dispatch('checkBusinessLicenseNumber', {
					businessLicenseNumber: this.companyInfo.businessLicenseNum.replace(/-/g, ''),
				})
				.then((res) => {
					res.data.available ? this.successCheckLicense() : this.failCheckLicense(res.data.message);
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		isInvalidatedBusinessLicenseNumber() {
			return (
				this.companyInvalidClass.businessLicenseNum === 'invalid' || this.companyInfo.businessLicenseNum.length === 0
			);
		},
		successCheckLicense() {
			this.$toasted.show('확인되었습니다.');
			this.isConfirmedLicense = true;
			this.companyInvalidClass.businessLicenseNum = false;
			this.companyInvalidClass.businessLicenseNumReason = '';
		},
		failCheckLicense(errMsg) {
			if (errMsg === businessLicenseNumFailMessage.DUPLICATE_NUM) {
				this.companyInvalidClass.businessLicenseNumReason = DUPLICATE_BUSINESSLICENSENUM;
				const message = `<b>입력하신 사업자등록번호는 이미 기업회원으로 가입하였습니다.</b><br /><br />
            &nbsp;・ 기업회원으로 가입한 담당자를 통해 고객포털 > 사용자관리 > 초대를 통해 <br />
            &nbsp;&nbsp;&nbsp; 고객포털을 이용해주시기 바랍니다. <br />
            &nbsp;・ 다른 사업자등록번호를 입력하시거나, 영업 담당자 및 1577-3019로 문의주시기 바랍니다.`;
				this.$modal.show(
					CommonAlert,
					{
						title: '사업자 등록 번호 중복',
						contents: message,
						buttons: [
							{
								text: '확인',
								callback: () => {},
							},
						],
					},
					Object.assign({}, DEFAULT_MODAL_STYLE, { clickToClose: false }),
				);
			} else {
				this.$toasted.show(errMsg);
			}
		},
		requiredMessage(data, message) {
			if (_.isEmpty(data.trim())) {
				return '입력 필수값입니다.';
			}
			return message;
		},
		validateEmail(e) {
			if (checkEmail(e.currentTarget.value)) {
				this.managerInvalidClass.email = false;
				this.managerInvalidClass.emailReason = VALID_EMAIL;
			} else {
				this.managerInvalidClass.email = true;
				this.managerInvalidClass.emailReason = INVALID_EMAIL;
			}
		},
		async setTerms() {
			await this.$store.dispatch('setCustomerTermsList');
			this.termsList = this.$store.getters.getCustomerTermsList;
			this.termsComponentKey += 1;
		},
		getValidateCheckList() {
			return [
				{
					check: this.isCorporateNameInvalid,
					focus: () => {
						if (this.isDomsInvite) return;
						this.$refs.corporateName.focus();
						this.companyInvalidClass.corporateName = true;
					},
				},
				{
					check: this.isCeoNameInvalid,
					focus: () => {
						if (this.isDomsInvite) return;
						this.$refs.ceoName.focus();
						this.companyInvalidClass.ceoName = true;
					},
				},
				{
					check: this.isBusinessNumberInvalid,
					focus: () => {
						if (this.isDomsInvite) return;
						this.$refs.businessLicenseNumber.focus();
						this.companyInvalidClass.businessLicenseNum = true;
						this.companyInvalidClass.businessLicenseNumReason = NEED_TO_CONFIRM_BUSINESSLICENSENUM;
						this.requiredMessage(this.companyInfo.businessLicenseNum, '사업자 등록번호 진위 확인이 필요합니다.');
					},
				},
				{
					check: this.isLoginIdInvalid,
					focus: () => {
						this.$refs.loginComponent.$refs.loginId.focus();
						this.loginInvalidClass.loginIdReason = INVALID_LOGINID;
						this.loginInvalidClass.loginId = true;
					},
				},
				{
					check: this.isPasswordInvalid,
					focus: () => {
						this.$refs.loginComponent.$refs.password.focus();
						this.loginInvalidClass.password = true;
						this.loginInvalidClass.passwordReason =
							this.loginInvalidClass.passwordReason || INVALID_PASSWORD_LENGTH_CHARACTER;
					},
				},
				{
					check: this.isPasswordConfirmInvalid,
					focus: () => {
						this.$refs.loginComponent.$refs.passwordConfirm.focus();
						this.loginInvalidClass.passwordConfirm = true;
						this.loginInvalidClass.passwordConfirmReason = PASSWORD_MATCH_FAIL;
					},
				},
				{
					check: this.isAuthenticationInvalid,
					focus: () => {
						this.$refs.phoneAuthentication.$refs.authButton.focus();
						this.managerInvalidClass.ci = true;
						this.managerInvalidClass.ciReason = AUTHENTICATION_CONFIRM_FAIL;
					},
				},
				{
					check: this.isEmailInvalid,
					focus: () => {
						this.$refs.email.focus();
						this.managerInvalidClass.email = true;
						this.managerInvalidClass.emailReason = INVALID_EMAIL;
					},
				},
				{
					check: this.isRequiredAllCheckedInvalid,
					focus: () => {
						this.$toasted.show(REQUIRED_CHECKED_TERM_FAIL);
					},
				},
			];
		},
		allValidationCheck() {
			const checkList = this.getValidateCheckList();

			for (let i in checkList) {
				if (checkList[i].check) {
					checkList[i].focus();
					break;
				}
			}
			this.loginComponentKey.idx += 1;
			return (
				!this.companyInvalidClass.corporateName &&
				!this.companyInvalidClass.businessLicenseNum &&
				!this.loginInvalidClass.loginId &&
				!this.loginInvalidClass.password &&
				!this.loginInvalidClass.passwordConfirm &&
				!this.managerInvalidClass.ci &&
				!this.managerInvalidClass.email &&
				this.isRequiredAllChecked
			);
		},
		async handleSignup() {
			const isValidate = this.allValidationCheck();
			if (!isValidate) {
				console.log('회원가입 실패');
				return;
			}

			// TODO: 약관 정리
			const agreeResult = [];
			for (let i = 0; i < this.termsList.length; i++) {
				agreeResult.push({
					termsId: this.termsList[i].termsId,
					agreed: this.termsList[i].agreed,
					required: this.termsList[i].required,
				});
			}
			Object.assign(this.companyFetchInfo.companyInfo, {
				corporateName: this.companyInfo.corporateName,
				businessLicenseNum: this.companyInfo.businessLicenseNum,
				ceoName: this.companyInfo.ceoName,
			});

			const signupData = Object.assign(
				{
					companyData: this.companyFetchInfo,
					accountTwoFactorType: this.useSecondFactor ? 'EMAIL' : 'NONE',
					agreementList: agreeResult,
					profileUrl: '',
					inviteCode: this.linkCode,
				},
				this.loginInfo,
				this.managerInfo,
			);

			try {
				const response = await signupCustomer(signupData);
				this.updateConfirmSigninParam();
				this.isDomsInvite ? this.$store.commit('CLEAR_LINK_CODES') : '';
			} catch (response) {
				if (response.status !== 200) {
					this.observeErrCode(
						response.data?.errorCode,
						'아이디, 이메일, 생년월일, 전화번호, 연속되는 숫자와 문자 등은 <br />비밀번호로 사용하실 수 없습니다.',
						this.$toasted.show,
					);
				}
			}
		},
		updateUseSecondFactor(factor) {
			this.useSecondFactor = factor;
		},
		clearAuthInfo() {
			this.clearManagerInfo();
			this.clearManagerInvalidClass();
		},
		clearManagerInfo() {
			this.managerInfo.phoneNumber = null;
			this.managerInfo.ci = null;
			this.managerInfo.name = null;
			this.managerInfo.birthDay = null;
		},
		clearManagerInvalidClass() {
			this.managerInvalidClass.ci = null;
			this.managerInvalidClass.ciReason = null;
		},
		setLoginInfo(loginData, loginInvalidData) {
			this.loginInfo = { ...loginData };
			this.loginInvalidClass = { ...loginInvalidData };
		},
		updateTermsData(terms) {
			this.termsList = terms.termsList;
			this.isRequiredAllChecked = terms.isRequiredAllChecked;
			this.isCheckAll = terms.isCheckAll;
		},
		updateConfirmSigninParam() {
			this.disableCloseConfirmEmit();
			this.$emit('updateConfirmSigninParam', {
				canSendConfirmEmail: true,
				email: this.managerInfo.email,
				loginId: this.loginInfo.loginId,
				password: this.loginInfo.password,
			});
		},
		setCompanyByDomsInvite() {
			this.companyInfo.corporateName = this.isDomsInvite ? this.corporateNameProps : '';
			this.companyInvalidClass.corporateName = false;
			this.companyInfo.businessLicenseNum = this.isDomsInvite ? this.businessLicenseNumProps : '';
			this.companyInvalidClass.businessLicenseNum = false;
			this.companyInfo.ceoName = this.isDomsInvite ? this.ceoNameProps : '';
			this.companyInvalidClass.ceoName = false;
			this.isConfirmedLicense = this.isDomsInvite;
		},
		async validateDuplicateEmail() {
			if (_.isEmpty(this.managerInfo.email)) {
				return;
			}
			let response = await duplicationEmail(this.managerInfo.email);
			if (response.data) {
				this.managerInvalidClass.email = true;
				this.managerInvalidClass.emailReason = ALREADY_USED_EMAIL;
			}
		},
		disableCloseConfirmEmit() {
			this.$emit('disableCloseCorifirm');
		},
		validatePasswordByIdentity() {
			if (
				password_validation.checkIdentityCharacters(
					this.loginInfo.password,
					this.managerInfo.phoneNumber,
					this.managerInfo.birthDay,
					this.managerInfo.email,
					this.loginInfo.loginId,
				)
			) {
				return;
			}
			this.loginInvalidClass.password = true;
			this.loginInvalidClass.passwordReason = INVALID_PASSWORD_CONTENT;
		},
		validateCeoName(e) {
			const ceoName = this.trimAll(e.target.value);
			this.companyInfo.ceoName = ceoName;
			this.companyInvalidClass.ceoName = !(this.checkInputLengthTrim(e, 2, 100) && !_.isEmpty(ceoName));
		},
		allValidationCheckButton() {
			if (
				this.isCorporateNameInvalid ||
				this.isCeoNameInvalid ||
				this.isBusinessNumberInvalid ||
				this.isLoginIdInvalid ||
				this.isPasswordInvalid ||
				this.isPasswordConfirmInvalid ||
				this.isAuthenticationInvalid ||
				this.isEmailInvalid ||
				this.isRequiredAllCheckedInvalid
			) {
				this.completedValidation = 'disabled';
				this.disabledButton = true;
				return;
			}
			this.completedValidation = '';
			this.disabledButton = false;
		},
	},
	watch: {
		'managerInfo.email'() {
			if (this.managerInfo.email && this.checkEmail(this.managerInfo.email) && this.validateDuplicateEmail()) {
				this.$emit('updateManagerInfo', this.managerInfo);
			}
		},
		updateLoginInfo() {
			this.setLoginInfo(this.updateLoginInfo.loginInfo, this.updateLoginInfo.loginInvalidClass);
		},
		isCorporateNameInvalid() {
			this.allValidationCheckButton();
		},
		isCeoNameInvalid() {
			this.allValidationCheckButton();
		},
		isBusinessNumberInvalid() {
			this.allValidationCheckButton();
		},
		isLoginIdInvalid() {
			this.allValidationCheckButton();
		},
		isPasswordInvalid() {
			this.allValidationCheckButton();
		},
		isPasswordConfirmInvalid() {
			this.allValidationCheckButton();
		},
		isAuthenticationInvalid() {
			this.allValidationCheckButton();
		},
		isEmailInvalid() {
			this.allValidationCheckButton();
		},
		isRequiredAllCheckedInvalid() {
			this.allValidationCheckButton();
		},
	},
};
</script>

<style scoped></style>
